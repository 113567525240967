import Vue from 'vue'
import App from './App.vue'
import VueI18n from "vue-i18n";
import storage from "./assets/scripts/storage";
import lang_zh from "./assets/lang/zh";
import lang_en from "./assets/lang/en";
import http from "./assets/scripts/http";
import util from "./assets/scripts/util";

Vue.config.productionTip = true;

Vue.prototype.$storage = storage;
Vue.prototype.$http = http;
Vue.prototype.$util = util;

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: storage.get('lang', 'zh_CN'),
    messages: {
        zh_CN: {
            lang: lang_zh,
        },
        en_US: {
            lang: lang_en,
        }
    }
});

new Vue({
    i18n,
    render: h => h(App)
}).$mount('#app')
