import util from "./util";

const storage = (appKey) => {
    let data = {
        appKey: appKey,
        init: function () {
            let appCache = localStorage.getItem(this.appKey);
            if (!appCache) {
                localStorage.setItem(this.appKey, JSON.stringify({uk: util.uuid()}));
            }
        },
        cache(obj) {
            if (!obj) {
                let jsonStr = localStorage.getItem(this.appKey)
                if (!jsonStr) {
                    this.init();
                    jsonStr = localStorage.getItem(this.appKey);
                }
                return JSON.parse(jsonStr)
            } else {
                localStorage.setItem(this.appKey, JSON.stringify(obj));
                return obj;
            }
        },
        set: function (itemKey, val) {
            const obj = this.cache();
            obj[itemKey] = val;
            this.cache(obj)
            return val;
        },
        get: function (itemKey, def) {
            //this.checkExpire(itemKey);
            return this.cache()[itemKey] || (def ? this.set(itemKey, def) : null);
        },
        del: function (itemKey) {
            const obj = this.cache();
            delete obj[itemKey];
            this.cache(obj)
        },
        /*expire: function (itemKey, expireTime) {
            let expires = this.get('expires', {});
            expires[itemKey] = (new Date().getTime() + expireTime * 1000);
            this.set('expires', expires);
        },*/
        checkExpire: function (itemKey) {
            const obj = this.cache();
            let expires = obj['expires'] || {};
            if (expires[itemKey] && expires[itemKey] < new Date().getTime()) {
                delete expires[itemKey];
                this.set('expires', expires);
                this.del(itemKey);
                return false;
            }
            return true;
        },
        clean: function () {
            localStorage.removeItem(this.appKey)
        },
    };
    data.init();
    return data;
}
export default new storage('hst.login');
