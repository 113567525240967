<template>
    <Dialog ref="dialog" :title="$t('lang.create.title')" :clickModel="false">
        <div class="form">
            <div class="form-item">
                <span class="input-title">{{$t('lang.create.pca')}}</span>
                <select ref="province" v-model="pca.province.selected" @change="loadPca(pca.province.selected.id,2)">
                    <option v-if="pca.province.list.length===0">{{$t('lang.create.placeholder.province')}}</option>
                    <option v-for="(item,index) in pca.province.list" :key="index" :value="item">{{$i18n.locale==='zh_CN'? (item.name+' ('+item.pinyin+')'):(item.pinyin)}}</option>
                </select>
                <select ref="city" v-model="pca.city.selected" @change="loadPca(pca.city.selected.id,3)" style="margin: 0 20px">
                    <option v-if="pca.city.list.length===0">{{$t('lang.create.placeholder.city')}}</option>
                    <option v-for="(item,index) in pca.city.list" :key="index" :value="item">{{$i18n.locale==='zh_CN'? (item.name+' ('+item.pinyin+')'):(item.pinyin)}}</option>
                </select>
                <select ref="area" v-model="pca.area.selected" @change="loadPca(pca.area.selected.id,4)">
                    <option v-if="pca.area.list.length===0">{{$t('lang.create.placeholder.area')}}</option>
                    <option v-for="(item,index) in pca.area.list" :key="index" :value="item">{{$i18n.locale==='zh_CN'? (item.name+' ('+item.pinyin+')'):(item.pinyin)}}</option>
                </select>
            </div>
            <div class="form-item">
                <span class="input-title">{{$t('lang.create.companyName')}}</span>
                <input ref="companyName" maxlength="18" :placeholder="$t('lang.create.placeholder.companyName')" @change="map.placeSearch.search($refs['companyName'].value)"/>
            </div>
            <div class="form-item">
                <span class="input-title">{{$t('lang.create.companyUser')}}</span>
                <input ref="companyUser" style="flex:0.3" maxlength="6" :placeholder="$t('lang.create.placeholder.companyUser')"/>
                <input ref="companyUserPhone" style="flex:0.3;margin-left: 10px" maxlength="11" :placeholder="$t('lang.create.placeholder.companyUserPhone')"/>
                <input ref="verifyCode" style="flex:0.2;margin-left: 10px" maxlength="11" :placeholder="$t('lang.create.placeholder.verifyCode')"/>
                <input style="background-color: #33978b;color: #FFF;border: 0;flex:0.2;margin-left: 10px" ref="verifyCodeBtn" type="button" :value="$t('lang.reset.verifyCodeBtn')" @click="getVerifyCode"/>
            </div>
            <div class="form-item">
                <span class="input-title">{{$t('lang.create.companyAddress')}}</span>
                <input ref="companyAddress" readonly :placeholder="$t('lang.create.placeholder.companyAddress')"/>
            </div>
            <div class="form-item">
                <div class="map-container" id="map-container"></div>
            </div>
            <div class="form-item">
                <input style="margin: auto;background-color: #33978b;color: #FFF;border: 0" ref="submitBtn" type="button" :value="$t('lang.create.submitBtn')" @click="save"/>
            </div>
            <div id="address-search-panel" style="display: none"></div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "../components/Dialog";
    import AMapLoader from '@amap/amap-jsapi-loader';

    export default {
        components: {Dialog},
        data() {
            return {
                sended: false,
                pca: {
                    province: {
                        list: [],
                        selected: null
                    },
                    city: {
                        list: [],
                        selected: null
                    },
                    area: {
                        list: [],
                        selected: null
                    }
                },
                map: {
                    root: null,
                    obj: null,
                    placeSearch: null,
                    current: {
                        lng: null,
                        lat: null,
                    }
                },
            }
        },
        methods: {
            loadPca(parentId, level, result) {
                let _that = this;
                if (level === 4) {
                    _that.map.obj.setCity(parentId);
                    _that.map.placeSearch.setCity(parentId);
                    if (_that.$refs['companyName'].value) {
                        _that.map.placeSearch.search(_that.$refs['companyName'].value)
                    }
                    return;
                }
                _that.$http.post('/area/list', {pageNo: 1, pageSize: 100, parentId: parentId}).then(res => {
                    if (_that.$util.isSuccessful(res)) {
                        if (level === 1) {
                            _that.pca.province.list = res.data.data.records.map(d => {
                                return {id: d.id, name: d.name, pinyin: d.pinyin}
                            });
                            if (_that.pca.province.list.length > 0) {
                                if (result) {
                                    let ps = _that.pca.province.list.filter(d => d.name === result.province);
                                    if (ps && ps.length > 0) {
                                        _that.pca.province.selected = ps[0];
                                    } else {
                                        _that.pca.province.selected = _that.pca.province.list[0];
                                    }
                                } else {
                                    _that.pca.province.selected = _that.pca.province.list[0];
                                }
                                _that.loadPca(_that.pca.province.selected.id, 2, result);
                            }
                        } else if (level === 2) {
                            _that.pca.city.list = res.data.data.records.map(d => {
                                return {id: d.id, name: d.name, pinyin: d.pinyin}
                            });
                            if (_that.pca.city.list.length > 0) {
                                if (result) {
                                    let ps = _that.pca.city.list.filter(d => d.name === result.city);
                                    if (ps && ps.length > 0) {
                                        _that.pca.city.selected = ps[0];
                                    } else {
                                        _that.pca.city.selected = _that.pca.city.list[0];
                                    }
                                } else {
                                    _that.pca.city.selected = _that.pca.city.list[0];
                                }
                                _that.loadPca(_that.pca.city.selected.id, 3, result)
                            }
                        } else if (level === 3) {
                            _that.pca.area.list = res.data.data.records.map(d => {
                                return {id: d.id, name: d.name, pinyin: d.pinyin}
                            });
                            _that.pca.area.selected = _that.pca.area.list[0];
                            _that.loadPca(_that.pca.area.selected.id, 4);
                        }
                    }
                });
            },
            loadMap(callback) {
                let _that = this;
                AMapLoader.load({
                    "key": "c9ffaf706f2ca07a06b22d90bc626c03",              // 申请好的Web端开发者Key，首次调用 load 时必填
                    "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    "plugins": ['AMap.PlaceSearch', 'AMap.Geocoder'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                }).then((AMap) => {
                    setTimeout(() => {
                        _that.map.obj = new AMap.Map('map-container', {
                            resizeEnable: true,
                            zoom: 11,
                        });
                        let geocoder = new AMap.Geocoder({
                            radius: 1000 //范围，默认：500
                        });
                        _that.map.obj.on('click', function (e) {
                            _that.map.current.lng = e.lnglat.lng;
                            _that.map.current.lat = e.lnglat.lat;
                            geocoder.getAddress([e.lnglat.lng, e.lnglat.lat], function (status, result) {
                                if (status === 'complete' && result.regeocode) {
                                    _that.$refs['companyAddress'].value = result.regeocode.formattedAddress;
                                }
                            });
                        });
                        _that.map.placeSearch = new AMap.PlaceSearch({
                            map: _that.map.obj,
                            pageSize: 10, // 单页显示结果条数
                            pageIndex: 1, // 页码
                            citylimit: true,  //是否强制限制在设置的城市内搜索
                            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围,
                            panel: 'address-search-panel',
                        });
                        _that.map.placeSearch.myMarkerClick = AMap.Event.addListener(_that.map.placeSearch, "markerClick", (e) => {
                            _that.$refs['companyAddress'].value = e.data.pname + e.data.cityname + e.data.adname + e.data.address;
                            _that.map.current.lng = e.data.location.lng;
                            _that.map.current.lat = e.data.location.lat;
                        });
                        _that.map.obj.getCity(result => {
                            callback(result);
                        }, null);
                    }, 0);
                }).catch(() => {
                    alert(_that.$t('lang.create.inputAlert.loadMapError'));
                    _that.$refs['dialog'].close();
                });
            },
            show() {
                let _that = this;
                Object.assign(_that.$data, _that.$options.data());
                _that.$refs['dialog'].show();
                _that.loadMap((result) => {
                    _that.loadPca("100000", 1, result);
                });
            },
            checkPhone() {
                let _that = this;
                let refCompanyUserPhone = _that.$refs['companyUserPhone'];
                let refSubmitBtn = _that.$refs['submitBtn'];
                if (_that.$util.isEmpty(refCompanyUserPhone.value)) {
                    _that.$util.tipOnBtn(refSubmitBtn, _that.$t('lang.create.submitBtn'), _that.$t('lang.create.inputAlert.companyUserPhone'))
                    refCompanyUserPhone.focus();
                    return false;
                }
                if (!_that.$util.isMobile(refCompanyUserPhone.value)) {
                    _that.$util.tipOnBtn(refSubmitBtn, _that.$t('lang.create.submitBtn'), _that.$t('lang.create.inputAlert.companyUserPhoneFormat'))
                    refCompanyUserPhone.focus();
                    return false;
                }
                return true;
            },
            getVerifyCode() {
                let _that = this;
                if (_that.sended || !_that.checkPhone()) {
                    return;
                }
                let refCompanyUserPhone = _that.$refs['companyUserPhone'];
                let refVerifyCodeBtn = _that.$refs['verifyCodeBtn'];
                _that.$http.post('/login/sendCodeNoe', {
                    phoneNumber: refCompanyUserPhone.value,
                }).then(res => {
                    if (_that.$util.isSuccessful(res)) {
                        _that.sended = true;
                        let ds = 180;
                        refVerifyCodeBtn.value = _that.$t('lang.create.verifyCodeBtnSend').replace('$s', ds);
                        let interval = setInterval(() => {
                            if (ds > 1) {
                                ds = ds - 1;
                                refVerifyCodeBtn.value = _that.$t('lang.create.verifyCodeBtnSend').replace('$s', ds);
                            } else {
                                refVerifyCodeBtn.value = _that.$t('lang.create.verifyCodeBtn');
                                clearInterval(interval)
                                _that.sended = false;
                            }
                        }, 1000);
                    } else {
                        _that.$util.tipOnBtn(_that.$refs['submitBtn'], _that.$t('lang.create.submitBtn'), _that.$t('lang.create.inputAlert.sendFailed'))
                    }
                });
            },
            save() {
                let _that = this;
                let province = _that.$refs['province'];
                let city = _that.$refs['city'];
                let area = _that.$refs['area'];
                let companyName = _that.$refs['companyName'];
                let companyUser = _that.$refs['companyUser'];
                let companyUserPhone = _that.$refs['companyUserPhone'];
                let verifyCode = _that.$refs['verifyCode'];
                let companyAddress = _that.$refs['companyAddress'];
                let refSubmitBtn = _that.$refs['submitBtn'];
                let langSubmitBtn = _that.$t('lang.create.submitBtn');
                if (!_that.pca.province.selected) {
                    _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.province'))
                    province.focus();
                    return;
                }
                if (!_that.pca.city.selected) {
                    _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.city'))
                    city.focus();
                    return;
                }
                if (!_that.pca.area.selected) {
                    _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.area'))
                    area.focus();
                    return;
                }
                if (_that.$util.isEmpty(companyName.value)) {
                    _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.companyName'))
                    companyName.focus();
                    return;
                }
                if (_that.$util.isEmpty(companyUser.value)) {
                    _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.companyUser'))
                    companyUser.focus();
                    return;
                }
                if (!_that.checkPhone()) {
                    return;
                }
                if (_that.$util.isEmpty(companyUserPhone.value)) {
                    _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.companyUserPhone'))
                    companyUserPhone.focus();
                    return;
                }
                if (_that.$util.isEmpty(verifyCode.value)) {
                    _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.verifyCode'))
                    verifyCode.focus();
                    return;
                }
                if (_that.$util.isEmpty(companyAddress.value)) {
                    _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.companyAddress'))
                    companyAddress.focus();
                    return;
                }
                const formData = {
                    description: companyName.value,
                    name: companyName.value,
                    provinceId: _that.pca.province.selected.id,
                    cityId: _that.pca.city.selected.id,
                    areaId: _that.pca.area.selected.id,
                    address: companyAddress.value,
                    lng: _that.map.current.lng,
                    lat: _that.map.current.lat,
                    code: verifyCode.value,
                    userName: companyUser.value,
                    phoneNumber: companyUserPhone.value,
                    creater: 'user', updater: 'user',
                }
                _that.$http.post('/community/reg', formData).then(res => {
                    if (_that.$util.isSuccessful(res)) {
                        _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.success'), 5000)
                        setTimeout(() => {
                            _that.$refs['dialog'].close();
                        }, 5000);
                    } else {
                        _that.$util.tipOnBtn(refSubmitBtn, langSubmitBtn, _that.$t('lang.create.inputAlert.failed'))
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .dialog .form {
        width: 900px;
        box-sizing: border-box;
        padding: 0 20px 20px;
        font-size: 14px;
        color: #666;
        overflow: auto;
    }

    .dialog .form-item {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dialog .form-item .input-title {
        width: 140px;
        display: block;
    }

    .dialog .form-item select,
    .dialog .form-item input {
        height: 36px;
        line-height: 36px;
        flex: 1;
        display: block;
        box-sizing: border-box;
        border-radius: 4px;
        outline: none;
        border: 1px solid #EEE;
        font-size: 14px;
        padding-left: 10px;
        color: #666;
        margin: auto;
        overflow: hidden;
    }

    .dialog .form-item .map-container {
        height: 400px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #EEE;
    }

    /deep/ .amap-logo,
    /deep/ .amap-copyright {
        display: none !important;
    }
</style>